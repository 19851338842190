<template>
<v-container>
    <v-row dense>

    <v-col
        v-for="(item, i) in items"
        :key="i"
        cols="3"
    >
        <v-card
        :color="item.color"
        dark
        >
        <div class="d-flex flex-no-wrap justify-space-between">
            <div>
            <v-card-title
                class="text-h5"
                v-text="item.title"
            >{{items}}</v-card-title>

            <v-card-subtitle class="text-h2" v-text="item.count"></v-card-subtitle>
            </div>
        </div>
        <v-card-actions>
            <v-list-item class="grow">
                <!-- <v-list-item-avatar color="grey darken-3">
                <v-img
                    class="elevation-6"
                    alt=""
                    src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                <v-list-item-title>Evan You</v-list-item-title>
                </v-list-item-content> -->

                <v-row
                align="center"
                justify="end"
                >
                <v-icon class="mr-1">
                    {{item.icon}}
                </v-icon>
                <!-- <span class="subheading mr-2">256</span>
                <span class="mr-1">·</span>
                <v-icon class="mr-1">
                    mdi-share-variant
                </v-icon>
                <span class="subheading">45</span> -->
                </v-row>
            </v-list-item>
            </v-card-actions>
        </v-card>
    </v-col>
    </v-row>
</v-container>
</template>

<script>
  import axios from 'axios'
  
  export default {
    data () {
      return {
        baseUrl : 'https://api.awakkerja.com/public/Api/UD/',
        memuat : false,
        items: [],
      }
    },
    computed : {
      
    },
    watch: {},
    mounted () {
      this.setApiData()
      this.checkLogin()
    },
    methods: {
      checkLogin(){
        //check Login
        let vm = this
        setTimeout(function(){
          axios({
            method : 'get',
            url  : vm.baseUrl+'Auth/checkToken',
            headers : {
              'Authorization' : vm.$session.get('jwt')
            }
          }).then(function(response){
            if(response.data.status!==200){
              vm.$session.destroy()
              vm.$router.push('/')
            }
          })
        },1000)
        //end check login
      },
      setApiData(){
        this.getData().then(response=>{
          this.items = response.data
        })
      },
      getData(){
        return new Promise((resolve,reject)=>{ 
            let vm = this
            axios({
            method : 'get',
            url  : vm.baseUrl+'dashboard',
            headers : {
              'Authorization' : vm.$session.get('jwt')
            }
           }).then(function(response){
            if(response.status==200){
              resolve(response)
            }else{
              reject('Terjadi Kesalahan, Hubungi programmer')
            }
          })
        }) 
      }
    },
  }
</script>